<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <material-card
          color="green"
          title="Lojas"
          text="Configurações de Lojas"
        >
          <v-list>
            <v-list-item-group v-model="model">
              <v-list-item v-for="(item, i) in lojas" :key="i">
                <v-list-item-content>
                  <v-list-item-icon>
                    <button class="mr-4" @click="editar(item)">
                      <b>EDITAR</b>
                    </button>
                  </v-list-item-icon>
                  <button @click="selecionar(i)">
                    <v-list-item-title v-text="item.nome"></v-list-item-title>
                  </button>
                  <v-container v-if="selecionado === i">
                    <p>
                      Nome: <b>{{ item.nome }}</b>
                    </p>
                    <p>
                      CNPJ: <b>{{ item.cnpj }}</b>
                    </p>
                    <p v-if="item.endereco">
                      Endereço:
                      <b
                        >{{ item.endereco.rua }}, {{ item.endereco.numero }} -
                        {{ item.endereco.bairro }},
                        {{ item.endereco.cidade }}/{{ item.endereco.estado }}</b
                      >
                    </p>
                    <p v-if="item.frete">
                      Frete:
                      <b>R$ {{ item.frete.toFixed(2).replace(".", ",") }}</b>
                    </p>
                    <p>
                      Telefone: <b>{{ item.telefone }}</b>
                    </p>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </material-card>
      </v-flex>

      <v-flex xs12 md8>
        <material-card
          color="green"
          title="Funcionários"
          text="Controle dos Funcionários"
        >
          <v-flex xs12 text-xs-right>
            <v-btn
              class="mx-0 font-weight-light"
              color="success"
              @click="dialogCriar = true"
              >Criar</v-btn
            >
          </v-flex>
          <v-list>
            <v-list-item-group v-model="model_func">
              <v-list-item v-for="(item, i) in funcionarios" :key="i">
                <v-list-item-content>
                  <v-list-item-icon>
                    <button class="mr-4" @click="editarFuncionario(item)">
                      <b>EDITAR</b>
                    </button>
                  </v-list-item-icon>
                    <v-list-item-title v-text="item.nome"></v-list-item-title>
                  <!-- <v-container v-if="selecionado === i">
                    <p>
                      Nome: <b>{{ item.nome }}</b>
                    </p>
                  </v-container> -->
                  <br/>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </material-card>
      </v-flex>

      <v-dialog v-model="dialogeditar" v-if="selecionado" width="500">
        <v-card class="pa-md-3 mx-lg-auto">
          <v-card-title
            class="headline grey lighten-2 px-5"
            primary-title
          ></v-card-title>
          <h3 class="ml-4">Dados</h3>
          <div class="px-5">
            <v-text-field
              label="Nome"
              v-model="selecionado.nome"
            ></v-text-field>
            <v-text-field
              label="CNPJ"
              v-model="selecionado.cnpj"
            ></v-text-field>
            <v-text-field
              label="Frete"
              v-model="selecionado.frete"
            ></v-text-field>
            <v-text-field
              label="Telefone"
              v-model="selecionado.telefone"
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <h3 class="ml-4">Endereço</h3>

          <div class="px-5">
            <v-text-field
              label="Rua"
              v-model="selecionado.endereco.rua"
            ></v-text-field>
            <v-text-field
              label="Número"
              v-model="selecionado.endereco.numero"
            ></v-text-field>
            <v-text-field
              label="Complemento"
              v-model="selecionado.endereco.complemento"
            ></v-text-field>
            <v-text-field
              label="Bairro"
              v-model="selecionado.endereco.bairro"
            ></v-text-field>
            <v-text-field
              label="Cidade"
              v-model="selecionado.endereco.cidade"
            ></v-text-field>
            <v-text-field
              label="Estado"
              v-model="selecionado.endereco.estado"
            ></v-text-field>
            <v-text-field
              label="País"
              v-model="selecionado.endereco.pais"
            ></v-text-field>
            <v-text-field
              label="CEP"
              v-model="selecionado.endereco.cep"
            ></v-text-field>
            <v-text-field
              label="Latitude"
              v-model="selecionado.endereco.latitude"
            ></v-text-field>
            <v-text-field
              label="Longitude"
              v-model="selecionado.endereco.longitude"
            ></v-text-field>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="atualizar()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCriar" width="900">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >Criar Funcionário</v-card-title
          >
          <v-card-text>
            Nome:
            <v-text-field v-model="criar.nome"></v-text-field>
          </v-card-text>
          <v-card-text>
            Telefone:
            <v-text-field v-model="criar.telefone"></v-text-field>
          </v-card-text>

          <v-card-text>
            E-mail do usuário:
            <v-text-field v-model="criar.email" type="email"></v-text-field>
          </v-card-text>

          <v-card-text>
            CPF:
            <v-text-field v-model="criar.cpf"></v-text-field>
          </v-card-text>

          <input
            class="ml-3 mt-4"
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />

          <v-divider></v-divider>

          <h3 class="ml-4">Endereço</h3>

          <div class="px-5">
            <v-text-field
              label="Rua"
              v-model="criar.endereco_dados.rua"
            ></v-text-field>
            <v-text-field
              label="Número"
              v-model="criar.endereco_dados.numero"
            ></v-text-field>
            <v-text-field
              label="Complemento"
              v-model="criar.endereco_dados.complemento"
            ></v-text-field>
            <v-text-field
              label="Bairro"
              v-model="criar.endereco_dados.bairro"
            ></v-text-field>
            <v-text-field
              label="Cidade"
              v-model="criar.endereco_dados.cidade"
            ></v-text-field>
            <v-text-field
              label="Estado"
              v-model="criar.endereco_dados.estado"
            ></v-text-field>
            <v-text-field
              label="País"
              v-model="criar.endereco_dados.pais"
            ></v-text-field>
            <v-text-field
              label="CEP"
              v-model="criar.endereco_dados.cep"
            ></v-text-field>
          </div>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="criarFuncionario()"
              >Criar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEditarFuncionario" width="900">
        <v-card v-if="selecionadoFunc">
          <v-card-title class="headline grey lighten-2" primary-title
            >Editar Funcionário</v-card-title
          >
          <v-card-text>
            Nome:
            <v-text-field v-model="selecionadoFunc.nome"></v-text-field>
          </v-card-text>
          <v-card-text>
            Telefone:
            <v-text-field v-model="selecionadoFunc.telefone"></v-text-field>
          </v-card-text>

          <v-card-text>
            E-mail do usuário:
            <v-text-field v-model="selecionadoFunc.email" type="email"></v-text-field>
          </v-card-text>

          <v-card-text>
            CPF:
            <v-text-field v-model="selecionadoFunc.cpf"></v-text-field>
          </v-card-text>

          <input
            class="ml-3 mt-4"
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />

          <v-divider></v-divider>

          <h3 class="ml-4">Endereço</h3>

          <div class="px-5">
            <v-text-field
              label="Rua"
              v-model="selecionadoFunc.endereco.rua"
            ></v-text-field>
            <v-text-field
              label="Número"
              v-model="selecionadoFunc.endereco.numero"
            ></v-text-field>
            <v-text-field
              label="Complemento"
              v-model="selecionadoFunc.endereco.complemento"
            ></v-text-field>
            <v-text-field
              label="Bairro"
              v-model="selecionadoFunc.endereco.bairro"
            ></v-text-field>
            <v-text-field
              label="Cidade"
              v-model="selecionadoFunc.endereco.cidade"
            ></v-text-field>
            <v-text-field
              label="Estado"
              v-model="selecionadoFunc.endereco.estado"
            ></v-text-field>
            <v-text-field
              label="País"
              v-model="selecionadoFunc.endereco.pais"
            ></v-text-field>
            <v-text-field
              label="CEP"
              v-model="selecionadoFunc.endereco.cep"
            ></v-text-field>
          </div>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="editarFuncionarioDados()"
              >Editar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-flex
        xs12
        md4
      >
        <material-card class="v-card-profile">
          <v-avatar
            slot="offset"
            class="mx-auto d-block"
            size="130"
          >
            <img
              src="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
            >
          </v-avatar>
          <v-card-text class="text-xs-center">
            <h4 class="card-title font-weight-light">{{usuario.nome}}</h4>
            <p class="card-description font-weight-light">{{usuario.email}}</p>
            
          </v-card-text>
        </material-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      usuario: {
        nome: "",
        email: "teste@teste.com",
      },
      selecionado: null,
      dialogeditar: true,
      dialogEditarFuncionario: false,
      selecionadoFunc: null,
      criar: {
        nome: "",
        email: "",
        telefone: "",
        cpf: "",
        endereco_dados: {
          rua: "",
          cep: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          estado: "",
          pais: "",
        },
      },
      model: 1,
      model_func: 1,
      lojas: [],
      file: null,
      funcionarios: [],
      dialogCriar: false,
    };
  },
  mounted() {
    this.getLojas();
    this.getFuncionarios();
  },
  methods: {
    getUserInfo() {
      let dados = JSON.parse(localStorage.getItem("user"));
      this.usuario.email = dados.email;
      //this.$http.get(`/user/${dados.id}`).then("")
    },
    getLojas() {
      try {
        this.$http.get("/lojas").then((res) => {
          this.lojas = res.data.lojas;
        });
      } catch (err) {
        // console.log(err);
        if(err.error) alert(err.error)
            
      }
    },
    getFuncionarios() {
      this.$http
        .get("/funcionarios")
        .then((res) => {
          // console.log(res.data);

          this.funcionarios = res.data.funcionarios;
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    selecionar(i) {
      this.selecionado = i;
    },
    editar(item) {
      this.selecionado = item;
      this.dialogeditar = true;
    },
    atualizar() {
      let endereco = this.selecionado.endereco;
      let dados = this.selecionado;
      dados.frete = parseFloat(dados.frete);

      this.$http
        .put(`/loja/${dados._id}`, dados)
        .then((res) => {
          alert("Dados atualizados");
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });

      this.$http
        .put(`/endereco/${endereco._id}`, endereco)
        .then((res) => {
          alert("Endereço atualizado.");
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
          // console.log("Não foi possível atualizar o endereço.");
        });

      this.$forceUpdate();

      this.dialogeditar = false;
      this.selecionado = null;
    },
    criarFuncionario() {
      this.$http
        .post("/funcionario", this.criar)
        .then((res) => {
          alert("Funcionário criado!");
          this.$forceUpdate();
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    editarFuncionario(func_id){
      this.$http
        .get(`/funcionario/${func_id._id}`)
        .then((res) => {
          // console.log(res.data);
          this.selecionadoFunc = res.data.funcionario;
          this.selecionadoFunc.email = res.data.funcionario.user.email;
          this.dialogEditarFuncionario=true;
          // console.log(res.data);
          this.$forceUpdate();
        })
        .catch((err) => {
          if(err.error) alert(err.error)
            
        });
    },
    editarFuncionarioDados(){
      this.$http.put(`/funcionario/${this.selecionadoFunc.user._id}`, this.selecionadoFunc )
                .then((res) => {
                  alert("Atualizado!");
                }).catch(err => {alert(err.error)});
    }
  },
  beforeMount() {
    this.getUserInfo();
  },
};
</script>
